import dashboardAxios, { ApiResponse } from "../api";
import { User } from "../types";
import { apiPaths } from "../paths";
import useAuth from "./useAuth";
import { useEffect, useState } from "react";
import { generateApiPath } from "../utils/helpers";

interface UpdateUserProps {
  user: Partial<User>;
}

interface UseUserResult {
  user?: User;
  loading: boolean;
  updateUser: (props: UpdateUserProps) => Promise<ApiResponse>;
  sending: boolean;
}

/***
 * Provides access to user data and functions to update them.
 * @param userId ID of the user to work with. If not given, use the current user.
 */
const useUser: (userId?: number) => UseUserResult = (userId) => {
  const { user, updateUser: doUpdateUser } = useAuth();

  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<User | null>(null);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      dashboardAxios
        .get(
          generateApiPath(apiPaths.user, {
            apiVersion: "v1",
            id: userId.toString(),
          })
        )
        .then((result) => {
          setUserData(result.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setUserData(user);
      setLoading(false);
    }
  }, [userId, user]);

  const updateUser: UseUserResult["updateUser"] = async ({ user }) => {
    setSending(true);
    const updateMethod =
      !userId && doUpdateUser !== null
        ? doUpdateUser
        : (user: Partial<User>) =>
            dashboardAxios.patch(
              generateApiPath(apiPaths.user, {
                apiVersion: "v1",
                id: userId?.toString(),
              }),
              user
            );
    return updateMethod(user)
      .then(() => {
        return { success: true };
      })
      .catch(() => {
        return { success: false };
      })
      .finally(() => {
        setSending(false);
      });
  };

  return {
    loading,
    user: userData || undefined,
    updateUser,
    sending: sending,
  };
};

export default useUser;
