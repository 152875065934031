import React, { memo, useEffect, useState } from "react";

import { Card, Grid } from "@mui/material";
import { WithProduct } from "@src/types";
import { useDashboardState } from "@src/app.state";
import { formatData } from "@src/utils/typing";
import SusTypography from "@components/SusTypography";
import SusWidget from "@src/templates/SusWidget";
import SusBox from "@components/SusBox";

const ProductWidget: React.FC<{ data?: WithProduct }> = memo(({ data }) => {
  const { locale, viewConfigKey } = useDashboardState();
  const [concatSize, setConcatSize] = useState("");
  const [concatPurity, setConcatPurity] = useState("");

  useEffect(() => {
    if (!data) {
      return;
    }
    if (viewConfigKey !== "EUBC") {
      const sizeMin = formatData(data.productSizeMin, "decimal", locale);
      const sizeMax = formatData(data.productSizeMax, "decimal", locale);
      setConcatPurity(`${data.productPurityMin || ""}`);
      setConcatSize(
        sizeMin && sizeMax
          ? `${sizeMin} - ${sizeMax} ${data.productSizeUnit || ""}`.trim()
          : `${sizeMin || sizeMax} ${data.productSizeUnit || ""}`.trim()
      );
    }
  }, [data, locale]);

  if (!data) {
    return <SusTypography>No product info given</SusTypography>;
  }

  const cardProps = {
    sx: {
      width: "100%",
      boxShadow: "none",
      border: "1px solid rgba(34, 34, 34, 0.125)",
      borderRadius: "13px",
    },
  };

  const productDetails =
    viewConfigKey === "EUBC"
      ? [
          {
            name: "Name",
            value: data.productNameEn,
          },
          {
            name: "Size",
            value: data.productSize,
          },
          {
            name: "Purity",
            value: data.productPurity,
          },
          {
            name: "Packaging",
            value: data.productPackagingEn,
          },
          {
            name: "Origin",
            value: data.productCountryOfOrigin,
          },
        ]
      : [
          {
            name: "Name",
            value: data.productNameEn,
          },
          {
            name: "Size",
            value: concatSize,
          },
          {
            name: "Purity",
            value: concatPurity,
          },
          {
            name: "Origin",
            value: data.productCountryOfOrigin,
          },
        ];

  return (
    <SusWidget title="Product Details" className={"product-details-card"}>
      <Grid container mb={1}>
        {productDetails.map((productDetails, index) => (
          <Grid item display="flex" xs={12} sm={6} md={4} p={0.5} key={index}>
            <Grid container>
              <Card {...cardProps}>
                <SusBox display="flex" xs={6} p={1} flexDirection="column" sx={{ width: "100%" }}>
                  <SusTypography
                    variant="caption"
                    fontWeight="regular"
                    display="flex"
                    textTransform="uppercase"
                    fontSize="sm"
                  >
                    {productDetails.name}
                  </SusTypography>
                  <SusTypography
                    variant="button"
                    fontWeight="bold"
                    p={0.5}
                    px={0}
                    sx={{ fontSize: "16px" }}
                  >
                    {productDetails.value}
                  </SusTypography>
                </SusBox>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </SusWidget>
  );
});

export default ProductWidget;
