/* eslint-disable camelcase */

import React, { useEffect, useState } from "react";

// @mui material components
import {
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Slide,
  Typography,
} from "@mui/material";

import useStyles from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleSmall,
  faEllipsisVertical,
  faPen,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";

import { cloneDeep, delay } from "lodash";
import useAuth from "@hooks/useAuth";
import dashboardAxios from "@src/api";
import { apiPaths } from "@src/paths";
import { generateApiPath } from "@src/utils/helpers";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import SusAvatar from "@components/SusAvatar";
import SusButton from "@components/SusButton";
import { navbarIconButton } from "@components/DashboardNavbar/styles";
import SusWidget from "@src/templates/SusWidget";
import { TransitionGroup } from "react-transition-group";
import SusEditor from "@components/SusEditor/SusEditor";
import { Comment } from "@src/types";
import colors from "@theme/base/colors";

interface IProps {
  title: string;
  comments: Comment[];
  commentedObjectRef?: string;
  companyId?: number;
  distributorId?: number;
  loading?: boolean;
}

const CommentBox: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  comments,
  commentedObjectRef,
  companyId,
  distributorId,
  loading,
}) => {
  const { user } = useAuth();
  const [comments_, setComments] = useState<Comment[]>([]);
  const classes = useStyles();
  const [focussedComment, setFocussedComment] = useState<number | null>(null);
  const [editComment, setEditComment] = useState<number | null>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  const [commentMenuAnchorEl, setCommentMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const commentMenuOpen = Boolean(commentMenuAnchorEl);

  const handleClickCommentMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCommentMenuAnchorEl(event.currentTarget);
  };
  const handleCloseCommentMenu = () => {
    setCommentMenuAnchorEl(null);
    delay(() => setConfirmDeleteOpen(false), 700);
  };

  useEffect(() => {
    setComments(comments);
  }, [comments]);

  const onSubmit = async (message: string) => {
    if (!user?.id) {
      return;
    }
    const response = await dashboardAxios.post(
      generateApiPath(apiPaths.comments, { apiVersion: "v1" }),
      {
        author: user.id,
        message,
        commentedObject: commentedObjectRef,
      }
    );
    if (response.status === 201) {
      setComments(cloneDeep(comments_.concat(response.data)));
    }
  };

  const onSubmitEdit = async (message: string) => {
    if (!user?.id || !editComment) {
      return;
    }
    const response = await dashboardAxios.patch(
      generateApiPath(apiPaths.comment, { apiVersion: "v1", id: editComment.toString() }),
      {
        message,
      }
    );
    if (response.status === 200) {
      setEditComment(null);
      const comment = comments_.find((comment) => comment.id === editComment);
      if (typeof comment !== "undefined") {
        comment.message = message;
      }
      setComments(cloneDeep(comments_));
    }
  };

  const onCancelEdit = () => {
    setEditComment(null);
  };

  const onDelete = async (id: number) => {
    const response = await dashboardAxios.delete(
      generateApiPath(apiPaths.comment, { apiVersion: "v1", id: id.toString() })
    );
    setConfirmDeleteOpen(false);
    if (response.status === 204) {
      setComments(comments_.filter((comment) => comment.id !== id));
    }
  };

  const { CommentMessage } = useStyles();

  const renderComments = comments_?.map(
    ({ id, message, author: { id: authorId, firstName, lastName }, createdAt }, key) => {
      const TimeDifference = (createdAt: Date) => {
        const diff = new Date().getTime() - createdAt.getTime();
        const minutesdiff = Math.floor(diff / 1000 / 60);
        const hoursdiff = Math.floor(minutesdiff / 60);
        const daysdiff = Math.floor(hoursdiff / 24);

        if (minutesdiff < 1) {
          return ` < 1 minute`;
        }
        if (hoursdiff < 1) {
          return `${minutesdiff} minutes`;
        }
        if (daysdiff < 1) {
          return `${hoursdiff} hours`;
        }
        return `${daysdiff} days`;
      };

      const contentProps = !loading
        ? { dangerouslySetInnerHTML: { __html: message } }
        : {
            children: <Skeleton width={180} />,
          };

      return (
        <Collapse key={id}>
          <SusBox
            display="flex"
            mt={key === 0 ? 0 : 0}
            p={3}
            className={classes.Comments}
            onMouseEnter={() => setFocussedComment(id)}
            sx={{ position: "relative" }}
          >
            <SusBox flexShrink={0}>
              {loading ? (
                <Skeleton>
                  <SusAvatar name={`${firstName} ${lastName}`} size="sm" />
                </Skeleton>
              ) : (
                <SusAvatar name={`${firstName} ${lastName}`} size="sm" />
              )}
            </SusBox>
            <SusBox flexGrow={1} ml={2}>
              <SusTypography
                variant="caption"
                fontWeight="medium"
                color="dark"
                textTransform="capitalize"
                display="flex"
                alignItems="center"
              >
                {loading ? (
                  <>
                    <Skeleton width={50} sx={{ marginRight: "5px" }} />
                    <Skeleton width={50} />
                  </>
                ) : (
                  <>
                    {`${firstName} ${lastName}`}
                    <FontAwesomeIcon
                      icon={faCircleSmall}
                      size="xs"
                      style={{ color: colors.secondary.main, padding: "0 5px", fontSize: "6px" }}
                    />
                    <SusTypography
                      variant="caption"
                      fontWeight="medium"
                      color="secondary"
                      textTransform="capitalize"
                      display="flex"
                    >
                      {TimeDifference(new Date(createdAt))} {" ago"}{" "}
                    </SusTypography>
                  </>
                )}
              </SusTypography>
              <SusBox mt={1} mb={2} lineHeight={0.75}>
                {editComment === id ? (
                  <SusEditor
                    company={companyId}
                    distributor={distributorId}
                    onSubmit={onSubmitEdit}
                    onCancel={onCancelEdit}
                    initialMessage={message}
                  />
                ) : (
                  <SusTypography
                    variant="body2"
                    fontWeight="regular"
                    color="text"
                    className={CommentMessage}
                    {...contentProps}
                  />
                )}
              </SusBox>
            </SusBox>
            {focussedComment === id && editComment !== id && authorId === user?.id && (
              <SusBox sx={{ position: "absolute", right: "10px" }}>
                <IconButton onClick={handleClickCommentMenu}>
                  <FontAwesomeIcon icon={faEllipsisVertical} size="xs" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  open={commentMenuOpen}
                  onClose={handleCloseCommentMenu}
                  anchorEl={commentMenuAnchorEl}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem onClick={handleCloseCommentMenu}>
                    <SusButton
                      variant="text"
                      sx={navbarIconButton}
                      size="small"
                      color="dark"
                      startIcon={<FontAwesomeIcon icon={faPen} />}
                      fullWidth
                      onClick={() => setEditComment(id)}
                    >
                      Edit
                    </SusButton>
                  </MenuItem>
                  <Divider sx={{ margin: "0.5rem 0" }} />
                  {confirmDeleteOpen ? (
                    <Slide direction="left" in={confirmDeleteOpen}>
                      <MenuItem>
                        <Typography variant="caption" sx={{ p: 1 }}>
                          Delete this comment?
                        </Typography>
                        <SusButton color="primary" onClick={() => onDelete(id)}>
                          Yes
                        </SusButton>
                      </MenuItem>
                    </Slide>
                  ) : (
                    <MenuItem color="error" onClick={() => setConfirmDeleteOpen(true)}>
                      <SusButton
                        variant="text"
                        sx={navbarIconButton}
                        size="small"
                        color="error"
                        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                        fullWidth
                      >
                        Delete
                      </SusButton>
                    </MenuItem>
                  )}
                </Menu>
              </SusBox>
            )}
          </SusBox>
          {key < comments_.length - 1 && <Divider sx={{ margin: "0" }} />}
        </Collapse>
      );
    }
  );

  const handleMouseLeave = () => {
    setFocussedComment(null);
    setCommentMenuAnchorEl(null);
    setConfirmDeleteOpen(false);
  };

  return (
    <SusWidget title={title} className="wrapper-commentbox">
      <SusBox onMouseLeave={handleMouseLeave}>
        <TransitionGroup component={null}>{renderComments}</TransitionGroup>
      </SusBox>
      {!loading && (
        <SusBox p={3} pt={0}>
          <SusBox mt={3} mb={1}>
            <SusBox display="flex" alignItems="top" mt={3}>
              <SusBox flexShrink={0} mr={2}>
                <SusAvatar name={`${user?.firstName} ${user?.lastName}`} size="sm" />
              </SusBox>
              <SusBox flexGrow={1}>
                <SusEditor
                  company={companyId}
                  distributor={distributorId}
                  onSubmit={onSubmit}
                  disabled={commentedObjectRef === undefined}
                  onCancel={null}
                  initialMessage=""
                />
              </SusBox>
            </SusBox>
          </SusBox>
        </SusBox>
      )}
    </SusWidget>
  );
};

export default CommentBox;
