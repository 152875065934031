import React, { useEffect } from "react";

import { Grid, TableContainer } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { useDashboardState } from "@src/app.state";
import { useContractPositions } from "@hooks/useApiData";
import { ContractPosition } from "@src/types";
import SusBox from "@components/SusBox";
import ModuleOverviewTable from "@components/ModuleOverviewTable/ModuleOverviewTable";
import ChartVolumeByVessel from "@components/widgets/Charts/ChartVolumeByVessel";
import ChartVolumeByProduct from "@components/widgets/Charts/ChartVolumeByProduct";
import { SimpleAggregationTile } from "@components/widgets/SimpleAggregationTile/SimpleAggregationTile";
import { paths } from "@src/paths";

const List: React.FC = () => {
  const {
    locale,
    setBreadcrumbs,
    viewSettings: { apiVersion, contractPosition: contractPositionViewSettings },
  } = useDashboardState();

  const { data: contractPositions, loading } = useContractPositions(apiVersion);

  useEffect(
    () => setBreadcrumbs([{ label: "Material in Transit", url: paths.materialInTransitOverview }]),
    []
  );

  const rows = contractPositions.filter((pos: ContractPosition) => pos.status === "IN_TRANSIT");
  return (
    <>
      <Helmet>
        <title>Material In Transit | PEK Dashboard</title>
      </Helmet>
      <SusBox mb={3} className="wrapper-status-overview">
        <Grid container spacing={3}>
          <Grid item sx={{ height: "450px" }}>
            <ChartVolumeByVessel filter={{ field: "status", value: "IN_TRANSIT" }} />
          </Grid>
          <Grid item sx={{ height: "450px" }}>
            <ChartVolumeByProduct
              amountField={"amount"}
              filter={{ field: "status", value: "IN_TRANSIT" }}
            />
          </Grid>
          <Grid item sx={{ height: "180px" }}>
            <SimpleAggregationTile
              title={"Total in transit (MT)"}
              objectClass="ContractPosition"
              fullHeight={true}
              filter={{ field: "status", value: "IN_TRANSIT" }}
              formatter={(val) => val.toLocaleString(locale, { maximumFractionDigits: 2 })}
              aggregationField="amount"
            />
          </Grid>
        </Grid>
      </SusBox>
      <Grid container spacing={3} mt={0.5}>
        <Grid item xs={12} md={12} xl={12}>
          <TableContainer sx={{ paddingTop: "1.25rem" }}>
            <ModuleOverviewTable
              rows={rows}
              loading={loading}
              columns={
                contractPositionViewSettings.fields.byStatus?.["IN_TRANSIT"]?.overviewList ||
                contractPositionViewSettings.fields.default.overviewList
              }
              localStoragePrefix="MaterialInTransitTable"
            />
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default List;
