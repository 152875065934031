import React, { useEffect } from "react";
import dashboardAxios from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { apiPaths, paths } from "../paths";
import useAlerts from "../hooks/useAlerts";
import { generateApiPath } from "../utils/helpers";

// react-router-dom components

// Image

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const alerts = useAlerts();
  const { key } = useParams();

  useEffect(() => {
    if (!key) {
      return;
    }
    dashboardAxios
      .post(generateApiPath(apiPaths.auth.confirmEmail, { key }), {}, { maxRedirects: 0 })
      .then((response) => {
        if (response.status === 200) {
          alerts.notifySuccess({
            title: "Success",
            content: "E-mail address confirmed. You can now log in.",
            autoHideDuration: 5000,
          });
        } else {
          alerts.notifyWarning({
            title: "Unexpected response",
            content:
              "Something did not go as expected. Please try again later or contact our support.",
            autoHideDuration: 5000,
          });
        }
      })
      .catch(() => {
        alerts.notifyError({
          title: "Error",
          content:
            "Something did not go as expected. Please try again later or contact our support.",
          autoHideDuration: 5000,
        });
      })
      .finally(() => navigate(paths.signIn));
  }, [key]);

  return <div />;
};

export default ConfirmEmail;
