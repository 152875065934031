import React, { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faFloppyDisk,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import useAlerts from "@hooks/useAlerts";
import { AttrType, ContractPosition, ContractPositionStatus, statusDetails } from "@src/types";
import { useDashboardState } from "@src/app.state";
import useAxios from "axios-hooks";
import { apiPaths, paths } from "@src/paths";
import { useContracts } from "@hooks/useApiData";
import { NumberParser, serializeContractPosition } from "@src/utils/typing";
import SusTypography from "@components/SusTypography";
import SusBox from "@components/SusBox";
import SusDialog from "@components/SusDialog/SusDialog";
import SusButton from "@components/SusButton";
import { generateApiPath } from "@src/utils/helpers";

interface ContractAutocompleteOption {
  label: string | undefined;
  id: number | undefined;
}

const NewContractPositionDialog: React.FC = () => {
  const navigate = useNavigate();
  const alerts = useAlerts();
  const [data, setData] = useState<ContractPosition>({ status: "UPCOMING" });
  const [contract, setContract] = useState<ContractAutocompleteOption | null>(null);
  const {
    currentDialog,
    setCurrentDialog,
    currentCompany,
    locale,
    viewSettings: { apiVersion, contractPosition: contractPositionViewSettings },
  } = useDashboardState();
  const [, postData] = useAxios(
    { url: generateApiPath(apiPaths.contractPositions, { apiVersion: "v1" }), method: "post" },
    {
      manual: true,
    }
  );

  const { data: contracts } = useContracts(apiVersion);

  const attrs =
    (data?.status && contractPositionViewSettings.fields.byStatus?.[data.status]?.detailView) ||
    contractPositionViewSettings.fields.default.detailView;

  const numberParser = useMemo(() => new NumberParser(locale), [locale]);

  const [errors, setErrors] = useState<{ [field: string]: string[] }>({});

  const handleSave = () => {
    if (!currentCompany?.companyId) {
      alerts.notifyError({
        title: "Error",
        content: "No current company selected!",
        autoHideDuration: 5000,
      });
    }
    const contractPos = serializeContractPosition({
      ...data,
      contract: contract?.id,
    });
    postData({ data: contractPos })
      .then((response) => {
        if (response?.data?.id) {
          navigate(
            generatePath(paths.contractPositionDetail, {
              contractId: response.data.contract,
              id: response.data.id,
            })
          );
          setCurrentDialog(null);
        }
      })
      .catch((error) => {
        setErrors(error?.response?.data || {});
        alerts.notifyError({
          title: "Error",
          content:
            error?.response?.data?.detail || "Sorry, something went wrong. Please try again later.",
          autoHideDuration: 5000,
        });
      });
  };

  const makeDialogInput = (label: string, key: keyof ContractPosition, type?: AttrType) => (
    <Grid item md={6} key={key}>
      <FormControl fullWidth variant="outlined">
        {type === "date" ? (
          <DatePicker
            value={data[key] as DateTime}
            label={label}
            onChange={(e: DateTime | null) => handleDateInputChange(key, e)}
            slotProps={{ textField: { variant: "outlined" } }}
          />
        ) : type === "number" ? (
          <TextField
            label={label}
            defaultValue={((data[key] as any) || "").toString()}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleNumberInputChange(key, e)}
            size="medium"
            inputProps={{ inputMode: "numeric" }}
            error={errors?.[key] && errors[key].length > 0}
            helperText={errors?.[key] && errors[key][0]}
          />
        ) : (
          <TextField
            defaultValue={data[key]}
            label={label}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleInputChange(key, e)}
            size="medium"
            error={errors?.[key] && errors[key].length > 0}
            helperText={errors?.[key] && errors[key][0]}
            fullWidth
          />
        )}
      </FormControl>
    </Grid>
  );

  const steps = useMemo(
    () => [
      {
        label: "Select Contract",
        content: (
          <>
            <Box mb={1} flexDirection="row">
              <SusTypography variant="body2">
                Please select an existing contract on which you want to add a position.
              </SusTypography>
            </Box>
            <SusBox flexDirection="row" display="flex" gap="1rem" pt="1rem" pb="0.5rem">
              <Autocomplete
                id="contractCustomerReference"
                options={contracts.map((contract) => ({
                  label: contract.customerReference,
                  id: contract.id,
                }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contract ID"
                    sx={{
                      width: "100% !important",
                      "& .MuiInput-root": {
                        fontSize: "16px",
                      },
                    }}
                  />
                )}
                getOptionLabel={(option) => option.label || "Contract without contract id"}
                onChange={(e, newValue) => handleContractChange(newValue)}
                sx={{ width: "100%" }}
              />
            </SusBox>
            <SusTypography
              variant="helperText"
              onClick={() => setCurrentDialog("NewContract")}
              sx={{ cursor: "pointer" }}
            >
              Create new contract id <strong>here</strong>.
            </SusTypography>
          </>
        ),
        nextStepAvailable: Boolean(contract?.id),
      },
      {
        label: "New Position",
        content: (
          <Box mb={1} flexDirection="row">
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: "1rem" }}>
              <TextField
                defaultValue={contract?.label}
                label="Contract ID"
                size="medium"
                disabled
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: "1rem" }}>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select-input"
                value={data.status}
                label="Status"
                onChange={(e) => handleStatusChange("status", e)}
              >
                {contractPositionViewSettings.availableStatus.map((status) => (
                  <MenuItem key={status} value={status}>
                    <SusBox>
                      <SusBox display="inline-flex" p="7px">
                        {statusDetails[status].icon}
                      </SusBox>{" "}
                      {statusDetails[status].label}
                    </SusBox>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid container spacing={2}>
              {attrs &&
                attrs
                  .filter(
                    ({ field }) =>
                      !["contractCustomerReference", "contractDistributorReference"].includes(field)
                  )
                  .map(({ field, label, type }) => makeDialogInput(label, field, type))}
            </Grid>
          </Box>
        ),
        nextStepAvailable: true,
      },
    ],
    [data, contract, attrs, contracts, errors]
  );

  const handleClose = () => {
    setCurrentDialog(null);
  };

  const handleInputChange = (
    key: keyof ContractPosition,
    e: React.ChangeEvent<{ value: unknown }> | null
  ) => {
    setData({
      ...data,
      [key]: e?.target?.value as string,
    });
  };

  const handleNumberInputChange = (
    key: keyof ContractPosition,
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = numberParser.parse((e?.target?.value as string) || "");
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleDateInputChange = (key: keyof ContractPosition, e: DateTime | null) => {
    setData({
      ...data,
      [key]: e,
    });
  };

  const handleStatusChange = (
    key: keyof ContractPosition,
    e: SelectChangeEvent<ContractPositionStatus> | null
  ) => {
    setData({
      ...data,
      [key]: e?.target?.value as string,
    });
  };

  const handleContractChange = (newValue: ContractAutocompleteOption | null) => {
    setContract(newValue);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <SusDialog
      title={`New Contract Position (${currentCompany?.companyName})`}
      open={currentDialog.type === "NewContractPosition"}
      onClose={handleClose}
      confirmClose
      actions={(handleCloseConfirm) => (
        <>
          {activeStep === 0 ? (
            <SusButton
              size="medium"
              color="text"
              variant="outlined"
              sx={{
                lineHeight: "1",
                mt: { xs: 1, md: 0 },
                mr: { xs: 1, sm: 1, md: 1 },
                ml: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minWidth: "auto",
              }}
              onClick={handleCloseConfirm}
            >
              <SusBox mr="0.5rem" color="text" display="inlineFlex">
                <FontAwesomeIcon icon={faTimes} size="xs" />
              </SusBox>
              cancel
            </SusButton>
          ) : (
            <SusButton
              size="medium"
              color="text"
              variant="outlined"
              sx={{
                lineHeight: "1",
                mt: { xs: 1, md: 0 },
                mr: { xs: 1, sm: 1, md: 1 },
                ml: "auto",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minWidth: "auto",
              }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <SusBox mr="0.5rem" color="text" display="inlineFlex">
                <FontAwesomeIcon icon={faAngleLeft} size="xs" />
              </SusBox>
              Back
            </SusButton>
          )}
          <SusButton
            size="medium"
            color="primary"
            variant="gradient"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "auto",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={activeStep < maxSteps - 1 ? handleNext : handleSave}
            disabled={!steps[activeStep].nextStepAvailable}
          >
            {activeStep < maxSteps - 1 ? "Next" : "Save"}
            <SusBox ml="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon
                icon={activeStep < maxSteps - 1 ? faAngleRight : faFloppyDisk}
                size="xs"
              />
            </SusBox>
          </SusButton>
        </>
      )}
    >
      <SusBox sx={{ flexGrow: 1 }}>
        {/*<Typography>{steps[activeStep].label}</Typography>*/}
        <SusBox p={2}>{steps[activeStep].content}</SusBox>
      </SusBox>
    </SusDialog>
  );
};

export default NewContractPositionDialog;
