import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarStar,
  faFileCirclePlus,
  faFileInvoice,
  faGaugeMax,
  faLayerPlus,
  faPalletBox,
  faShip,
  faTruckClock,
  faWarehouse,
} from "@fortawesome/pro-solid-svg-icons";
import SignIn from "./views/signIn";
import ConfirmEmail from "./views/confirmEmail";
import ResetPassword from "./views/reset-password";
import ContractsTable from "./views/contracts/list";
import ContractDetails from "./views/contracts/details";
import CallOffsTable from "./views/callOffs/list";
import CallOffDetails from "./views/callOffs/details";
import WarehousingTable from "./views/warehousing/list";
import WarehousingDetails from "./views/warehousing/details";
import ContractPositionDetails from "./views/contractPosition/details";
import MaterialInTransitTable from "./views/materialInTransit/list";
import MaterialInTransitDetails from "./views/materialInTransit/details";
import UpcomingVesselsTable from "./views/upcomingVessels/list";
import UpcomingVesselsDetails from "./views/upcomingVessels/details";
import { paths } from "./paths";
import ResetPasswordEnterNew from "./views/reset-password-enter-new";
import React, { ReactElement } from "react";
import { DialogType } from "./app.state";
import { NavigationElement, UserRole, ViewSettings } from "./types";
import ControlRoom from "./views/controlRoom";
import SettingsPage from "./views/settings/SettingsPage";
import colors from "@theme/base/colors";

export interface RouteDescr {
  name?: string;
  key: NavigationElement;
  type: "title" | "simple" | "divider" | "collapse" | "hidden" | "button";
  collapse?: RouteDescr[];
  noCollapse?: boolean;
  betaFeature?: boolean;
  newFeature?: boolean;
  route: string;
  public?: boolean;
  visible?: boolean;
  permission?: (role: UserRole | undefined) => boolean;
  icon?: (active: boolean) => ReactElement;
  component?: React.FC<any> | (() => React.ReactElement);
  onClick?: () => void;
}

/**
 * Returns a list of all available routes, optionally filtered by view settings.
 * @param setCurrentDialog
 * @param viewSettingsFilter
 */
const generateRoutes: (
  setCurrentDialog: (dialog: DialogType) => void,
  viewSettingsFilter: ViewSettings
) => RouteDescr[] = (setCurrentDialog, viewSettingsFilter) => [
  {
    name: "Sign-In",
    key: "signIn",
    type: "hidden",
    route: paths.signIn,
    public: true,
    visible: true,
    component: SignIn,
  } as RouteDescr,
  {
    name: "Confirm-Email",
    key: "confirmEmail",
    type: "hidden",
    route: paths.confirmEmail,
    public: true,
    visible: true,
    component: ConfirmEmail,
  } as RouteDescr,
  {
    name: "Settings",
    key: "settings",
    type: "hidden",
    route: paths.settings,
    public: false,
    visible: true,
    component: SettingsPage,
  } as RouteDescr,
  {
    name: "Reset-Password",
    key: "resetPassword",
    type: "hidden",
    route: paths.resetPassword,
    public: true,
    visible: true,
    component: ResetPassword,
  } as RouteDescr,
  {
    name: "Reset-Password-Enter-New",
    key: "resetPasswordEnterNew",
    type: "hidden",
    route: paths.resetPasswordEnterNew,
    public: true,
    visible: true,
    component: ResetPasswordEnterNew,
  } as RouteDescr,
  {
    name: "Control Room",
    key: "controlRoom",
    type: "simple",
    betaFeature: true,
    route: paths.controlRoom,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faGaugeMax}
        size="xs"
        fixedWidth
      />
    ),
    component: ControlRoom,
    visible: viewSettingsFilter.hasControlRoom,
  } as RouteDescr,
  {
    name: "Contracts",
    key: "contractOverview",
    type: "simple",
    route: paths.contractOverview,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faFileInvoice}
        size="xs"
        fixedWidth
      />
    ),
    component: ContractsTable,
    visible: viewSettingsFilter.uiModules.includes("Contracts"),
  } as RouteDescr,
  {
    name: "Contract Detail",
    key: "contractDetail",
    type: "hidden",
    route: paths.contractDetail,
    component: ContractDetails,
    visible: viewSettingsFilter.uiModules.includes("Contracts"),
  } as RouteDescr,
  {
    name: "Contract Position Detail",
    key: "contractPositionDetail",
    type: "hidden",
    route: paths.contractPositionDetail,
    component: ContractPositionDetails,
    visible: viewSettingsFilter.uiModules.includes("Contracts"),
  } as RouteDescr,
  {
    name: "Call-Offs",
    key: "callOffOverview",
    type: "simple",
    route: paths.callOffOverview,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faPalletBox}
        size="xs"
        fixedWidth
      />
    ),
    component: CallOffsTable,
    visible: viewSettingsFilter.uiModules.includes("CallOffs"),
  } as RouteDescr,
  {
    name: "Call-Off Detail",
    key: "callOffDetail",
    type: "hidden",
    route: paths.callOffDetail,
    component: CallOffDetails,
    visible: viewSettingsFilter.uiModules.includes("CallOffs"),
  } as RouteDescr,
  {
    name: "Warehousing",
    key: "warehousingOverview",
    type: "simple",
    noCollapse: true,
    betaFeature: true,
    route: paths.warehousingOverview,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faWarehouse}
        size="xs"
        fixedWidth
      />
    ),
    component: WarehousingTable,
    visible: viewSettingsFilter.uiModules.includes("Warehousing"),
  } as RouteDescr,
  {
    name: "Warehousing Detail",
    key: "warehousingDetail",
    type: "hidden",
    route: paths.warehousingDetail,
    component: WarehousingDetails,
    visible: viewSettingsFilter.uiModules.includes("Warehousing"),
  } as RouteDescr,
  {
    name: "Material in Transit",
    key: "materialInTransitOverview",
    type: "simple",
    noCollapse: true,
    route: paths.materialInTransitOverview,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faShip}
        size="xs"
        fixedWidth
      />
    ),
    component: MaterialInTransitTable,
    visible: viewSettingsFilter.uiModules.includes("MaterialInTransit"),
  } as RouteDescr,
  {
    name: "Material in Transit Detail",
    key: "materialInTransitDetail",
    type: "hidden",
    route: paths.materialInTransitDetail,
    component: MaterialInTransitDetails,
    visible: viewSettingsFilter.uiModules.includes("MaterialInTransit"),
  } as RouteDescr,
  {
    name: "Upcoming Vessels",
    key: "upcomingVesselsOverview",
    type: "simple",
    noCollapse: true,
    route: paths.upcomingVesselsOverview,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faCalendarStar}
        size="xs"
        fixedWidth
      />
    ),
    component: UpcomingVesselsTable,
    visible: viewSettingsFilter.uiModules.includes("Upcoming"),
  } as RouteDescr,
  {
    name: "Upcoming Vessels Detail",
    key: "upcomingVesselDetail",
    type: "hidden",
    route: paths.upcomingVesselDetail,
    component: UpcomingVesselsDetails,
    visible: viewSettingsFilter.uiModules.includes("Upcoming"),
  } as RouteDescr,
  {
    key: "divider1",
    type: "divider",
    permission: (role) => ["admin", "data_editor"].includes(role || ""),
    visible: viewSettingsFilter.manualObjectCreation,
  } as RouteDescr,
  {
    name: "New Contract",
    key: "newContract",
    type: "button",
    permission: (role) => ["admin", "data_editor"].includes(role || ""),
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faFileCirclePlus}
        size="xs"
        fixedWidth
      />
    ),
    onClick: () => setCurrentDialog("NewContract"),
    visible: viewSettingsFilter.manualObjectCreation,
  } as RouteDescr,
  {
    name: "New Position",
    key: "newContractPosition",
    type: "button",
    permission: (role) => ["admin", "data_editor"].includes(role || ""),
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faLayerPlus}
        size="xs"
        fixedWidth
      />
    ),
    onClick: () => setCurrentDialog("NewContractPosition"),
    visible: viewSettingsFilter.manualObjectCreation,
  } as RouteDescr,
  {
    name: "Request Quote",
    key: "newRequestQuote",
    type: "button",
    newFeature: true,
    icon: (active) => (
      <FontAwesomeIcon
        color={active ? colors.primary.focus : colors.black.main}
        icon={faTruckClock}
        size="xs"
        fixedWidth
      />
    ),
    onClick: () => setCurrentDialog("RequestQuoteDialog"),
    visible: viewSettingsFilter.requestQuoteButton,
  } as RouteDescr,
];

export default generateRoutes;
