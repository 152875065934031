import React, { createContext, useEffect, useState } from "react";
import { User } from "../types";
import { apiBaseUrl, apiPaths, paths } from "../paths";
import { isEqual } from "lodash";
import dashboardAxios, { ApiResponse } from "../api";
import axios from "axios";
import { LOGOUT_EVENT_NAME } from "../constants";
import { DateTime } from "luxon";
import { useLocation, useNavigate } from "react-router-dom";
import useAlerts from "../hooks/useAlerts";
import { trackUserChanged } from "../features/tracking";

export type AuthContext = {
  user: User | null;
  updateUser: ((user: Partial<User>) => Promise<boolean>) | null;
  requestMfaCode: ((email: string) => Promise<boolean>) | null;
  login:
    | ((
        email: string,
        password: string,
        mfaCode?: string
      ) => Promise<boolean | "mfa_required" | "mfa_invalid">)
    | null;
  resetPassword: ((email: string) => Promise<boolean>) | null;
  setNewPassword:
    | ((password1: string, password2: string, uid: string, token: string) => Promise<boolean>)
    | null;
  changePassword: ((params: IChangePasswordParams) => Promise<ApiResponse>) | null;
  logout: (() => void) | null;
  sessionExpiry: DateTime | null;
};

export const authContext = createContext<AuthContext>({
  user: null,
  updateUser: null,
  login: null,
  requestMfaCode: null,
  logout: null,
  resetPassword: null,
  setNewPassword: null,
  changePassword: null,
  sessionExpiry: null,
});

export interface IChangePasswordParams {
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}

export const AuthProvider: (props: React.PropsWithChildren<any>) => React.ReactElement = ({
  children,
}) => {
  const [sessionExpiry, setSessionExpiry] = useState<DateTime | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { notifyError } = useAlerts();

  const requestMfaCode = async (email: string): Promise<boolean> =>
    dashboardAxios
      .post(apiPaths.auth.mfa, {
        email,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        notifyError({
          title: "Error",
          content: "Your verification code could not be sent. Please try again later.",
          autoHideDuration: 5000,
        });
        return false;
      });

  const updateUser: AuthContext["updateUser"] = async (user) =>
    dashboardAxios
      .post(apiPaths.currentUser, user)
      .then((response) => setNewUser(response.data))
      .catch(() => false);

  const login: AuthContext["login"] = async (email: string, password: string, mfaCode?: string) =>
    dashboardAxios
      .post(apiPaths.auth.login, {
        email,
        password,
        ...(mfaCode ? { mfaCode } : {}),
      })
      .then(getUserDetails)
      .then((success) => {
        if (success) {
          const queryParams = new URLSearchParams(location.search);
          const redirectParam = (location.state || queryParams.get("next") || "") as string;
          if (redirectParam?.startsWith("http")) {
            window.location.href = redirectParam;
          } else {
            navigate(redirectParam || paths.home);
          }
        }
        return success;
      })
      .catch((error) => {
        if ("mfa" in error.response.data && error.response.data["mfa"][0] === "mfa_required") {
          return "mfa_required";
        } else if (
          "mfa" in error.response.data &&
          error.response.data["mfa"][0] === "mfa_invalid"
        ) {
          return "mfa_invalid";
        }
        return false;
      });

  const resetPassword: AuthContext["resetPassword"] = async (email) =>
    dashboardAxios
      .post(apiPaths.auth.resetPassword, {
        email,
      })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });

  const setNewPassword: AuthContext["setNewPassword"] = async (password1, password2, uid, token) =>
    axios // Don't use dashboardAxios, as this will break the names of the parameters new_password1 and new_password2 (would insert "_" before the number)
      .post(
        apiPaths.auth.setNewPassword,
        {
          new_password1: password1,
          new_password2: password2,
          uid: uid,
          token: token,
        },
        {
          withCredentials: true,
          baseURL: apiBaseUrl,
        }
      )
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });

  const changePassword: AuthContext["changePassword"] = async ({
    oldPassword,
    newPassword1,
    newPassword2,
  }) =>
    dashboardAxios
      .post(
        apiPaths.auth.changePassword,
        {
          old_password: oldPassword,
          new_password1: newPassword1,
          new_password2: newPassword2,
        },
        {
          withCredentials: true,
          baseURL: apiBaseUrl,
          params: {
            _noDecamelize: true,
          },
        }
      )
      .then(() => {
        return { success: true };
      })
      .catch((error) => {
        return {
          success: false,
          errors: new Map(Object.entries(error.response.data)),
        };
      });

  const logout: AuthContext["logout"] = () =>
    dashboardAxios.post(apiPaths.auth.logout, {}).finally(() => {
      setNewUser(null);
    });

  const setNewUser = (newUser: any) => {
    if (!isEqual(newUser, user)) {
      setUser(newUser);
      trackUserChanged(newUser);
    }
    return !!newUser;
  };

  const getUserDetails = () => {
    return dashboardAxios
      .get(apiPaths.currentUser)
      .then((response) => {
        const sessionExpiryRaw = response.headers["x-session-expires-at"];
        setSessionExpiry(DateTime.fromHTTP(sessionExpiryRaw));
        return setNewUser(response.data);
      })
      .catch(() => {
        setNewUser(null);
        return false;
      })
      .finally(() => {
        if (loading) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    // Get user details on initial render
    getUserDetails();

    // Logout on global LOGOUT event
    const logoutEventListener = () => logout();
    document.body.addEventListener(LOGOUT_EVENT_NAME, logoutEventListener);

    return () => {
      document.body.removeEventListener(LOGOUT_EVENT_NAME, logoutEventListener);
    };
  }, []);

  const auth: AuthContext = {
    user,
    updateUser,
    login,
    requestMfaCode,
    // signup,
    logout,
    resetPassword,
    setNewPassword,
    changePassword,
    sessionExpiry,
    // sendPasswordResetEmail,
    // confirmPasswordReset,
  };

  return <authContext.Provider value={auth}>{!loading && children}</authContext.Provider>;
};
