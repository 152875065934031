import React, { createContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { DjangoRestResult, Notification, NotificationSettings } from "../types";
import { apiBaseUrl, apiPaths } from "../paths";
import { isEqual } from "lodash";
import useAuth from "../hooks/useAuth";
import { NOTIFICATION_UPDATE_INTERVAL } from "../constants";
import { useNavigate } from "react-router-dom";
import useAlerts from "../hooks/useAlerts";
import dashboardAxios from "../api";
import { generateApiPath } from "../utils/helpers";

export type NotificationsContext = {
  readNotifications: Notification[] | null;
  unreadNotifications: Notification[] | null;
  notificationSettings: NotificationSettings | null;
  setNotificationSettings: ((notificationSettings: NotificationSettings) => void) | null;
  toggleUnreadStatus: ((notification: Notification) => void) | null;
  navigateToSubject: ((notification: Notification) => void) | null;
};

export const notificationsContext = createContext<NotificationsContext>({
  readNotifications: null,
  unreadNotifications: null,
  notificationSettings: null,
  setNotificationSettings: null,
  toggleUnreadStatus: null,
  navigateToSubject: null,
});

export const NotificationsProvider: (props: React.PropsWithChildren<any>) => React.ReactElement = ({
  children,
}) => {
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[] | null>(null);
  const [readNotifications, setReadNotifications] = useState<Notification[] | null>(null);
  const [notificationSettings, setNotificationSettings] = useState<NotificationSettings | null>(
    null
  );
  const navigate = useNavigate();
  const { notifyError } = useAlerts();
  const { user } = useAuth();

  const updateNotifications = () => {
    if (!user) {
      return;
    }
    dashboardAxios
      .get<DjangoRestResult>(generateApiPath(apiPaths.unreadNotifications, { apiVersion: "v1" }))
      .then(({ status, data }: AxiosResponse<DjangoRestResult>) => {
        if (status === 200) {
          const { results } = data;
          setUnreadNotifications(
            results.filter((notification) => !notification.targetDeleted) as Notification[]
          );
        }
      });
    dashboardAxios
      .get<DjangoRestResult>(generateApiPath(apiPaths.readNotifications, { apiVersion: "v1" }))
      .then(({ status, data }: AxiosResponse<DjangoRestResult>) => {
        if (status === 200) {
          const { results } = data;
          setReadNotifications(
            results.filter((notification) => !notification.targetDeleted) as Notification[]
          );
        }
      });
  };

  const getNotificationSettings = () => {
    if (!user) {
      return;
    }
    dashboardAxios.get(apiPaths.notificationSettings).then(({ status, data }: AxiosResponse) => {
      if (status === 200) {
        const { notificationSettings: notificationSettings_ } = data;
        if (!isEqual(notificationSettings_, notificationSettings)) {
          setNotificationSettings(notificationSettings_);
        }
      }
    });
  };

  const setAndUpdateNotificationSettings = async (notificationSettings_: NotificationSettings) => {
    setNotificationSettings(notificationSettings_);
    if (!user) {
      return false;
    }
    return await dashboardAxios
      .post(apiPaths.notificationSettings, { notificationSettings: notificationSettings_ })
      .then(({ status }: AxiosResponse) => {
        return status === 200;
      });
  };

  const toggleUnreadStatus = ({ id, unread }: Notification) => {
    dashboardAxios
      .patch(
        generateApiPath(apiPaths.notification, { apiVersion: "v1", id }),
        {
          unread: !unread,
        },
        {
          baseURL: apiBaseUrl,
        }
      )
      .then((result) => {
        if (result.status === 200 && readNotifications && unreadNotifications) {
          if (unread) {
            setUnreadNotifications(
              unreadNotifications.filter((notification) => notification.id !== id)
            );
            setReadNotifications(
              readNotifications
                .concat([result.data])
                .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            );
          } else {
            setReadNotifications(
              readNotifications.filter((notification) => notification.id !== id)
            );
            setUnreadNotifications(
              unreadNotifications
                .concat([result.data])
                .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            );
          }
        }
      })
      .catch(() => {
        notifyError({
          title: "Server Error",
          content: "Something went wrong",
        });
      });
  };

  const navigateToSubject = (notification: Notification) => {
    if (notification.href) {
      navigate(notification.href.replace(window.location.origin, ""));
    } else {
      navigate("#");
    }
  };

  useEffect(() => {
    updateNotifications();
    const interval = setInterval(updateNotifications, NOTIFICATION_UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, [user?.id]);

  useEffect(() => {
    getNotificationSettings();
  }, [user?.id]);

  const notifications: NotificationsContext = {
    readNotifications,
    unreadNotifications,
    notificationSettings,
    setNotificationSettings: setAndUpdateNotificationSettings,
    toggleUnreadStatus,
    navigateToSubject,
  };

  return (
    <notificationsContext.Provider value={notifications}>{children}</notificationsContext.Provider>
  );
};
