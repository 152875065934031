// import { AxiosResponseTransformer } from "axios";
import useAxios from "axios-hooks";
import { DATA_REFETCH_INTERVAL } from "../constants";
import useAuth from "./useAuth";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDashboardState } from "../app.state";
import { apiPaths, ApiVersion } from "../paths";
import { CallOff, Contract, ContractPosition } from "../types";
import {
  deserializeCallOff,
  deserializeContract,
  deserializeContractPosition,
} from "../utils/typing";
import { generateApiPath } from "@src/utils/helpers";

// import { camelizeKeys } from "humps";

export interface FetchingStrategy {
  trigger: "ONCE" | "INTERVAL" | "MANUAL";
  interval?: number;
  companyQuery?: {
    enabled: boolean;
    required: boolean;
  };
}

const useApiData: (
  url: string,
  method?: "get" | "post" | "patch",
  fetchingStrategy?: FetchingStrategy,
  params?: any,
  limit?: number,
  offset?: number
) => {
  data?: any;
  fetch: (isRefetch?: boolean) => any;
  loading: boolean;
  initialLoading: boolean;
  refetching: boolean;
  error: any;
} = (url, method = "get", fetchingStrategy = { trigger: "ONCE" }, params = {}, limit, offset) => {
  const currentCompany = useDashboardState((state) => state.currentCompany);
  const { user } = useAuth();
  const [isRefetch, setIsRefetch] = useState(false);

  const apiPath = useMemo(() => {
    const queryParams = new Map<string, string>();
    if (limit) {
      queryParams.set("limit", limit.toString());
    }
    if (offset) {
      queryParams.set("offset", offset.toString());
    }
    if (fetchingStrategy.companyQuery?.enabled && currentCompany?.companyId) {
      queryParams.set("company", currentCompany.companyId.toString());
    } else if (fetchingStrategy.companyQuery?.enabled && fetchingStrategy.companyQuery?.required) {
      return undefined;
    }
    const queryString =
      queryParams.size > 0
        ? `?${Array.from(queryParams)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : "";
    return `${url}${queryString}`;
  }, [url, currentCompany]);

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: apiPath,
      method,
      params,
    },
    {
      manual: true,
    }
  );

  const doFetch = useCallback(
    (isRefetch = false) => {
      if (!url) {
        return Promise.resolve();
      }
      setIsRefetch(isRefetch);
      return execute().then(() => setIsRefetch(false));
    },
    [execute]
  );

  useEffect(() => {
    if (fetchingStrategy.trigger === "INTERVAL") {
      const interval = setInterval(
        () => user && doFetch(true),
        fetchingStrategy.interval || DATA_REFETCH_INTERVAL
      );
      return () => clearInterval(interval);
    } else {
      return () => null;
    }
  }, [user?.id, apiPath, execute]);

  useEffect(() => {
    if (user && apiPath && fetchingStrategy.trigger !== "MANUAL") {
      doFetch(false).catch((error) => {
        if (error.name !== "CanceledError") {
          throw error;
        }
      });
    }
  }, [user?.id, apiPath]);

  return {
    data,
    fetch: doFetch,
    loading,
    initialLoading: loading && !isRefetch,
    refetching: loading && isRefetch,
    error,
  };
};

export interface ICachedApiDataProps {
  fetchingStrategy?: FetchingStrategy;
  limit?: number;
  offset?: number;
}

function useCachedApiData<T>(
  url: string,
  cacheKey: string,
  deserialize: (obj: any) => T
): (props?: ICachedApiDataProps) => {
  data: T[];
  totalCount: number;
  fetch: (isRefetch?: boolean) => any;
  loading: boolean;
  initialLoading: boolean;
  refetching: boolean;
  error: any;
} {
  return (props) => {
    const { fetchingStrategy, limit, offset } = props || {};
    const { cache, cacheSet } = useDashboardState();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<T[]>([]);

    const defaultFetchingStrategy = {
      trigger: "INTERVAL",
      companyQuery: { enabled: true, required: true },
    } as FetchingStrategy;

    const {
      data: rawData,
      fetch,
      loading,
      initialLoading,
      refetching,
      error,
    } = useApiData(url, "get", fetchingStrategy || defaultFetchingStrategy, {}, limit, offset);

    useEffect(() => {
      if (rawData?.results) {
        const deserializedData = rawData.results.map(deserialize);
        setData(deserializedData);
        cacheSet(cacheKey, deserializedData);
      }
      setTotalCount(rawData?.count || 0);
    }, [rawData]);

    useEffect(() => {
      setData(cache.get(cacheKey) || []);
    }, [cache.get(cacheKey)]);

    return {
      data,
      totalCount,
      fetch,
      loading: !cache.has(cacheKey) && loading,
      initialLoading,
      refetching,
      error,
    };
  };
}

export const useContractPositions = (apiVersion: ApiVersion, props?: ICachedApiDataProps) =>
  useCachedApiData<ContractPosition>(
    generateApiPath(apiPaths.contractPositions, { apiVersion }),
    "contractPositions",
    deserializeContractPosition
  )(props);

export const useContracts = (apiVersion: ApiVersion, props?: ICachedApiDataProps) =>
  useCachedApiData<Contract>(
    generateApiPath(apiPaths.contracts, { apiVersion }),
    "contracts",
    deserializeContract
  )(props);

export const useCallOffs = (apiVersion: ApiVersion, props?: ICachedApiDataProps) =>
  useCachedApiData<CallOff>(
    generateApiPath(apiPaths.callOffs, { apiVersion }),
    "callOffs",
    deserializeCallOff
  )(props);

export default useApiData;
