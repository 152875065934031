import colors from "@theme/base/colors";
import borders from "@theme/base/borders";
import pxToRem from "@theme/functions/pxToRem";

const { transparent } = colors;
const { borderRadius } = borders;

// types
type Types = any;

const stepper: Types = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
      borderRadius: borderRadius.lg,

      "&.MuiPaper-root": {
        backgroundColor: transparent,
      },
    },
  },
};

export default stepper;
