import { ViewSettings } from "../types";

const viewSettings: ViewSettings = {
  apiVersion: "v1",
  hasControlRoom: false,
  uiModules: [],
  availableObjectClasses: [],
  manualObjectCreation: false,
  requestQuoteButton: false,
  contract: {
    statusField: null,
    dataModifiable: false,
    availableStatus: [],
    documents: {
      comingSoon: false,
    },
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [],
      },
    },
  },
  contractPosition: {
    statusField: null,
    dataModifiable: false,
    availableStatus: [],
    documents: {
      comingSoon: true,
    },
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [],
      },
    },
  },
  callOff: {
    statusField: null,
    dataModifiable: false,
    availableStatus: [],
    documents: {
      comingSoon: true,
    },
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [],
      },
    },
  },
  callOffPosition: {
    statusField: null,
    dataModifiable: false,
    availableStatus: [],
    documents: {
      comingSoon: true,
    },
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [],
      },
    },
  },
};

export default viewSettings;
