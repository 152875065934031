import React, { useState } from "react";
import { useDashboardState } from "@src/app.state";
import useAlerts from "@hooks/useAlerts";
import { apiPaths } from "@src/paths";
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlaneTop } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";

import { SubmitHandler } from "react-hook-form/dist/types/form";
import useAuth from "@hooks/useAuth";
import dashboardAxios from "@src/api";
import SusDialog from "@components/SusDialog/SusDialog";
import SusBox from "@components/SusBox";

export interface ProductRequestQuoteSpecs {
  id?: string;
  nameEn?: string;
  purity?: string;
  size?: string;
  packagingEn?: string;
}

interface FormData {
  product: string;
  purity: string;
  size: string;
  amount: string;
  packagingEn: string;
  incoterms: string;
  incotermsCity: string;
  additionalInformation: string;
}

const RequestQuoteDialog: React.FC = () => {
  const { currentCompany, currentDialog, setCurrentDialog } = useDashboardState();
  const { user } = useAuth();
  const { register, handleSubmit } = useForm<FormData>();
  const { notifySuccess, notifyError } = useAlerts();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormData | undefined>();

  const onSubmit: SubmitHandler<FormData> = async (data, event) => {
    event?.preventDefault();
    setLoading(true);
    dashboardAxios
      .post(apiPaths.reorderRequest, {
        ...data,
        productQuality: data.purity,
        productSize: data.size,
        packaging: data.packagingEn,
        salesRepName: currentCompany?.companySalesRepName,
        salesRepEmail: currentCompany?.companySalesRepEmail,
        requesterName: `${user?.firstName} ${user?.lastName}`,
        requesterEmail: user?.email,
        company: currentCompany?.companyName,
      })
      .then(() => {
        setErrors(undefined);
        notifySuccess({
          title: "Success",
          content: "Request for quote sent.",
          autoHideDuration: 5000,
        });
        setCurrentDialog(null);
      })
      .catch((error) => {
        setErrors(error?.response?.data?.errors);
        notifyError({
          title: "Error",
          content: "Something went wrong. Please check your input.",
          autoHideDuration: 5000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === "backdropClick") {
      return;
    }
    setCurrentDialog(null);
  };

  return (
    <SusDialog
      title={`Quote request`}
      open={
        currentDialog.type === "ReOrderingDialog" || currentDialog.type === "RequestQuoteDialog"
      }
      onClose={handleClose}
      sx={{ "& .MuiPaper-root": { width: "700px" } }}
      actions={() => (
        <>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            size="medium"
            sx={{
              lineHeight: "1",
              mt: { xs: 1, md: 0 },
              mr: { xs: 1, sm: 1, md: 1 },
              ml: "",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minWidth: "auto",
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            <SusBox mr="0.5rem" color="white" display="inlineFlex">
              <FontAwesomeIcon icon={faPaperPlaneTop} size="xs" />
            </SusBox>
            Send
          </LoadingButton>
        </>
      )}
    >
      <SusBox p={2}>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Product</InputLabel>
          <OutlinedInput
            label="Product Quality"
            {...register("product")}
            error={!!errors && "product" in errors}
            required
            fullWidth
          />
          {!!errors && "product" in errors ? <span>{errors.product}</span> : null}
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Product Quality</InputLabel>
          <OutlinedInput
            label="Product Quality"
            {...register("purity")}
            error={!!errors && "purity" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Product Size</InputLabel>
          <OutlinedInput
            label="Product Size"
            {...register("size")}
            error={!!errors && "size" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Contract Amount</InputLabel>
          <OutlinedInput
            label="Contract Amount"
            {...register("amount")}
            error={!!errors && "amount" in errors}
            required
            fullWidth
          />
          {!!errors && "amount" in errors ? <span>{errors.amount}</span> : null}
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Packaging</InputLabel>
          <OutlinedInput
            label="Packaging"
            {...register("packagingEn")}
            error={!!errors && "packagingEn" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Incoterms</InputLabel>
          <OutlinedInput
            label="Incoterms"
            {...register("incoterms")}
            error={!!errors && "incoterms" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Incoterms Location</InputLabel>
          <OutlinedInput
            label="Incoterms Location"
            {...register("incotermsCity")}
            error={!!errors && "incotermsCity" in errors}
            required
            fullWidth
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1.5,
            "& .MuiInput-root": {
              fontSize: "16px",
            },
          }}
        >
          <InputLabel>Additional Information</InputLabel>
          <OutlinedInput
            label="Additional Information"
            {...register("additionalInformation")}
            error={!!errors && "additionalInformation" in errors}
            fullWidth
            multiline
            rows={2}
          />
          {errors && "Additional Information" in errors && (
            <FormHelperText id="standard-weight-helper-text">
              {errors.additionalInformation}
            </FormHelperText>
          )}
        </FormControl>
      </SusBox>
    </SusDialog>
  );
};

export default RequestQuoteDialog;
